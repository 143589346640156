<template>
    <v-img :src="cSrc" :height="height" :width="width" contain>
        <template v-slot:placeholder>
            <v-icon :size="size">{{ icon }}</v-icon>
        </template>
    </v-img>
</template>

<script>
export default {
    name: 'BT-Photo',
    props: {
        height: {
            type: String,
            default: 'auto'
        },
        icon: {
            type: String,
            default: 'mdi-camera'
        },
        id: {
            type: String,
            default: null
        },
        size: {
            type: Number,
            default: 45
        },
        src: {
            type: String,
            default: null
        },
        width: {
            type: String,
            default: 'auto'
        },
    },
    computed: {
        cSrc() {
            return this.src || `https://blitzittechimages.blob.core.windows.net/pod/${this.id}`;
        }
    }
}
</script>